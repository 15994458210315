/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Dropdown, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "../Payments/Payments";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { useDispatch } from "react-redux";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import { Tooltip } from "@material-ui/core";
import Country from "../countries/Country";
import "../../../Components/styles/dashboard.scss";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import useOnClickOutside from "../../../helpers/outsideClick";
import Menu from "../../../Assets/Images/dots.png";
import debounce from 'lodash.debounce';
import useHandleDownload from "../../hook/useHandleDownload";  //le download function misent sur la page MusicGenre en creant hook
import { keys } from '@material-ui/core/styles/createBreakpoints';


const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);

const MusicGenre = () => {
  const route = window.location.pathname;
  var genreId = route.split("/").slice(-1)[0];
  const [loading, setLoading] = useState(false);
  const [allMusicMood, setAllMusicMood] = useState([]);
  const [userPlaylist, setUserPlaylist] = useState([]);

  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);

  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [countryList, setCountryList] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [Idc, setDownloadId] = useState();

  const {handledownload} = useHandleDownload();

  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({
                user: "Please check username or password",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const history = useNavigate();
  const dispatch = useDispatch();
  const getCountryList = async () => {
    await axios
      .get("https://restcountries.eu/rest/v2/all")
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getCountryList();
    getAllMusicMood();
    getByUserPlaylist();
  }, []);

  const getAllMusicMood = async () => {
    const data = {
      genreId: genreId,
    };
    await ApiPost(`music/get-music-by-genre/null `, data)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setAllMusicMood(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddToPlaylist = async (id) => {
    setLoading(true);
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  console.log("window.location", window.location.href);

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from page to page 
  const handlePlaySongs = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allMusicMood, index: key}));
    }
  };


  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center">
                    <Link to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    OnTouchStart={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    >
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </Link>
                    <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      {allMusicMood[0]?.genreData?.genreTitle}
                    </p>
                  </div>
                </div>
              </div>

              <div className="cus-grid-one pt-15">
                {allMusicMood && allMusicMood.length ? (
                  allMusicMood?.map((record, key) => {
                    return (
                      <div className="grid-items">
                        <NavLink 
                           to="/"
                           onClick={(e) => {
                            e.preventDefault();
                            history(-1);
                          }}
                         
                        >
                          <p>
                            {/* <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i> */}
                          </p>
                        </NavLink>
                           
                           
                        <div className="singer-box">
                          <div
                            className="singer-img"
                            onClick={() => 
                              handlePlaySongs(record, key)         // play all free songs on a list one by one from page to page 
                                     }
                            OnTouchStart={() => 
                              handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                          >
                            <img
                            
                            src={record && record.thumbnail}
                              className="object-cover"
                              alt=""
                            />
                          </div>
                          <div className="singer-text p-5">
                            <div className="flex items-center justify-between cursor-pointer">
                              <div
                                onClick={() => 
                                  handlePlaySongs(record, key)         // play all free songs on a list one by one from page to page 
                                         }
                                OnTouchStart={() => 
                                   handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                              >
                                <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                  {record.title}{" "}
                                </p>
                                <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                                  {record.artistData?.artistName +
                                    " " +
                                    record.artistData?.realName}{" "}
                                </p>
                              </div>

                              {(() => {
                                if (authUtil.isLoggedIn()) {
                                  return record.isPaid ? (
                                    userUtil
                                      ?.getMusicData()
                                      ?.includes(record._id) ? (
                                      <>
                                        <div
                                          className="menu_dd"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="menu-img"
                                            onClick={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`trends${record._id}`]:
                                                    !pre[`trends${record._id}`],
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={Menu}
                                              style={{ height: "25px" }}
                                              alt=""
                                            />
                                          </div>
                                          {open[`trends${record._id}`] && (
                                            <div
                                              className="menu-box"
                                              ref={NavigatorRef2}
                                            >
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                        musicId: record._id,
                                                        open: true,
                                                      })
                                                    : setLoginModal(true)
                                                }
                                                OnTouchStart={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                        musicId: record._id,
                                                        open: true,
                                                      })
                                                    : setLoginModal(true)
                                                }
                                              >
                                                Add to playlist
                                              </div>

                                              <div
                                                className="text-white p-2  cursor-pointer"
                                                onClick={() =>
                                                  dispatch(getMusicData(record))
                                                }
                                                OnTouchStart={() =>
                                                  dispatch(getMusicData(record))
                                                }
                                              >
                                                Play song now
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  history(`/song/${record._id}`)
                                                }
                                                OnTouchStart={() =>
                                                  history(`/song/${record._id}`)
                                                }
                                              >
                                                Details
                                              </div>
                                              <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                              OnTouchStart={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                            >
                                              Download
                                            </div>
                                            <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() => {
                                              shareLink(record._id);
                                            }}
                                            OnTouchStart={() => {
                                              shareLink(record._id);
                                            }}
                                          >
                                            <button 
                                             onClick={copy}>
                                                      {!copied
                                                        ? "Copy link"
                                                        : "Copy link"}
                                            </button>
                                          </div>
                                                    
                                            </div>
                                          )}
                                        </div>                                       
                                      </>
                                    ) : (
                                      <div
                                      onClick={() =>
                                        handlePlaySongs(record, key)         // play all free songs on a list one by one from page to page
                                      }
                                      OnTouchStart={() => 
                                        handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                                      >
                                        <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                      </div>
                                    )
                                  ) : (
                                    <div
                                      className="menu_dd"
                                      ref={NavigatorRef2}
                                    >
                                      <div
                                        className="menu-img"
                                        onClick={() => {
                                          setOpen((pre) => {
                                            return {
                                              ...pre,
                                              [`trends${record._id}`]:
                                                !pre[`trends${record._id}`],
                                            };
                                          });
                                        }}
                                      >
                                        <img
                                          src={Menu}
                                          style={{ height: "25px" }}
                                          alt=""
                                        />
                                      </div>
                                      {open[`trends${record._id}`] && (
                                        <div
                                          className="menu-box"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                            OnTouchStart={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                          >
                                            Add to playlist
                                          </div>

                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() => 
                                              handlePlaySongs(record, key)         // play all free songs on a list one by one from page to page 
                                                     }
                                            OnTouchStart={() => 
                                               handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                                          >
                                            Play song now
                                          </div>
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              history(`/song/${record._id}`)
                                            }
                                            OnTouchStart={() =>
                                              history(`/song/${record._id}`)
                                            }
                                          >
                                            Details
                                          </div>
                                          <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                              OnTouchStart={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                            >
                                              Download
                                            </div>
                                            <div
                                          className="text-white p-2 cursor-pointer"
                                        onClick={() => {
                                      shareLink(record._id);
                                                }}
                                        OnTouchStart={() => {
                                          shareLink(record._id);
                                                }}
                                          >
                                      <button onClick={copy}>
                                        {!copied
                                          ? "Copy link"
                                          : "Copy link"}
                                      </button>
                                  </div>
                                </div>
                               )}
                              </div>
                              );
                              } else {
                                  return record.isPaid ? (
                                    <div
                                    onClick={() =>
                                      handlePlaySongs(record, key)}       // play all free songs on a list one by one from page to page
                                    
                                    OnTouchStart={() => 
                                      handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                                    >
                                      <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                    </div>
                                  ) : (
                                    <div
                                      className="menu_dd"
                                      ref={NavigatorRef2}
                                    >
                                      <div
                                        className="menu-img"
                                        onClick={() => {
                                          setOpen((pre) => {
                                            return {
                                              ...pre,
                                              [`trends${record._id}`]:
                                                !pre[`trends${record._id}`],
                                            };
                                          });
                                        }}
                                      >
                                        <img
                                          src={Menu}
                                          style={{ height: "25px" }}
                                          alt=""
                                        />
                                      </div>

                                      {open[`trends${record._id}`] && (
                                        <div
                                          className="menu-box"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                            OnTouchStart={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                          >
                                            Add to playlist
                                          </div>

                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              handlePlaySongs(record, key)}       // play all free songs on a list one by one from page to page
                                            
                                            OnTouchStart={() => 
                                              handlePlaySongs(record, key)}      // play all free songs on a list one by one from page to page
                                          >
                                            Play song now
                                          </div>
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              history(`/song/${record._id}`)
                                            }
                                            OnTouchStart={() =>
                                              history(`/song/${record._id}`)
                                            }
                                          >
                                            Details
                                          </div>
                                          <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                              OnTouchStart={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record, key)
                                                  : setLoginModal(true);
                                              }}
                                            >
                                              Download
                                            </div>
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  shareLink(record._id);
                                                    }}
                                                  OnTouchStart={() => {
                                                  shareLink(record._id);
                                                  }}
                                                >
                                              <button onClick={copy}>
                                                {!copied
                                                  ? "Copy link"
                                                  : "Copy link"}
                                                </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    );
                  })
                ) : (
                  <div className="text-white" align="center">
                    No Data Found
                  </div>
                )}
              </div>
              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{
                                height: "450px",
                                overflowY: "overlay",
                              }}
                            >
                              {userPlaylist && userPlaylist.length ? (
                                userPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="singer-box">
                                        <div className="singer-img">
                                          <img
                                            src={
                                              (record &&
                                                record.Songs &&
                                                record.Songs.length &&
                                                record.Songs[0].musicImage) ||
                                              `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                            }
                                            style={{
                                              height: "150px",
                                              width: "335px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                        <div className="singer-text p-4">
                                          <div className="flex items-center justify-between">
                                            <div>
                                              <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                {record.playlist_name}
                                              </p>
                                            </div>
                                            <div>
                                              {record?.Songs?.map((rec) => {
                                                if (
                                                  rec._id ===
                                                  playlistModal.musicId
                                                ) {
                                                  return (
                                                    <>
                                                      <p className="cursor-pointer font-size-14 login-text-color">
                                                        added
                                                        <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="dots">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4 border-solid border-white rounded-b"
                          
                        >
                          <button
                            className="text-red-500 border-solid border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {/* //* Purchase modal */}
              {showModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="flex items-start justify-between p-5 border-b border-solid border-white-100 rounded-t-lg"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl font-bold text-white">
                            Buy this song or album
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto rounded-b-lg"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="mb-3 text-white leading-relaxed">
                            Like this artists music? Show your support by
                            purchasing their music.
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Title: {showModal.musicTitle}
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Price: ${showModal.amount}
                          </p>
                          <Elements stripe={stripePromise}>
                            <Payments
                              musicId={showModal.musicId}
                              amount={showModal.amount}
                              musicTitle={showModal.musicTitle}
                              musicArtistId={showModal.artistId}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            closeOnTouchStart
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />
        </main>
      </div>
    </div>
  );
};

export default MusicGenre;
