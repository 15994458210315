/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import { useState } from "react";
import { ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import debounce from 'lodash.debounce';


const Userlogin = (props) => {
  const [loginInputs, setLoginInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleOnChange = async (e) => {
    const { name, value } = e.target;
    setLoginInputs({ ...loginInputs, [name]: value });
  };

  const handleLogin = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      const data = {
        email: loginInputs.email,
        password: loginInputs.password,
      };
      setLoading(true);
      await ApiPost("users/login", data)
        .then((res) => {
          if (res.data.message === "Successfully logged in.") {
            setLoading(false);
            authUtil.setToken(res.data.data.token);
            userUtil.setUserInfo(res.data.data);
            props.setLoginModal(false);
            window.location.reload();
          } else if (res.msg) {
            setLoading(false);
            // setErrors({ user: "User does not exist." });
          } else {
            setLoading(false);
            // setErrors({
            // user: "Please check username or password",
            // });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };
  return (
    <div>
      <div className="animation justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto lg:w-1/3 md:w-4/5">
          <div className="relative modal-login-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-bold text-white"> </h3>

              <button
                className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => {
                  props.setLoginModal(false);
                }}
                OnTouchStart={() => {
                  props.setLoginModal(false);
                }}
              >
                <p>
                  <i className="fas fa-times login-text-color relative"></i>
                </p>
              </button>
            </div>
            <div className="relative p-6 flex-auto end-text l-pt-0">
              <div>
                <div className="text-white text-center">
                  This form is for fans and listeners. If you're an Artist,
                  Affiliate or Manager
                  <div>
                    <a
                      className="signup-link"
                      target="_blank"
                      href={"//" + "admin.7thcentury.co.uk/auth/login"}
                    >
                      Use this link to create your Account 
                    </a>
                    <div className="login_logo pl-5 pr-5 flex justify-center relative pb-6">
                      <img
                        src={
                          require("../../../Assets/Images/login-logo.png")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <p className="text-center font-size-14 pt-12">
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {errors["user"]}
                  </span>
                </p>
                <div className="flex flex-col items-left justify-center login-placeholder pt-5">
                  <div className="">
                    <span
                      style={{
                        color: "red",
                        top: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                    <input
                      type="email"
                      name="email"
                      className="form-control-login mb-8"
                      placeholder="Enter email"
                      // onChange={(e) => handleChange(e)}
                      onKeyUp={(event) => handleOnChange(event)}
                    />{" "}
                  </div>
                  <div className="">
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["password"]}
                    </span>
                    <input
                      type="password"
                      name="password"
                      className="form-control-login mb-8"
                      placeholder="Enter password"
                      // onChange={(e) => handleChange(e)}
                      onKeyUp={(event) => handleOnChange(event)}
                    />{" "}
                  </div>
                </div>
                <div>
                  <div className="flex justify-center">
                    <button
                      className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                      onClick={(event) => handleLogin(event)}
                      OnTouchStart={(event) => handleLogin(event)}
                      type="submit"
                    >
                      {" "}
                      Log in
                    </button>
                  </div>
                  <div>
                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 text-center mt-4">
                      Create a new account.
                    </p>
                    <p
                      className="font-size-16 login-text-color font-normal mt-3 mb-8 tracking-normal text-center cursor-pointer"
                      onClick={() => {
                        props.setSignupModal(true);
                        props.setLoginModal(false);
                      }}
                      OnTouchStart={() => {
                        props.setSignupModal(true);
                        props.setLoginModal(false);
                      }}
                    >
                      {" "}
                      Sign up
                    </p>
                    <p
                      className="font-size-18 white-text-color font-normal tracking-normal mb-0 text-center mt-4 cursor-pointer"
                      onClick={() => {
                        props.setLoginModal(false);
                        props.setSignupModal(false);
                        props.setForgotModal(true);
                      }}
                      OnTouchStart={() => {
                        props.setLoginModal(false);
                        props.setSignupModal(false);
                        props.setForgotModal(true);
                      }}
                    >
                      forgot password ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlogin;
